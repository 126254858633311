import React, { FC, useState, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Link,
} from '@mui/material';
import InputField from 'shared/components/input/input.component';

import useLogin from '../hooks/login.service';
import LoadingButton from 'shared/components/loading-button/loading-button.component';
import MaskedNumber from 'shared/components/masked-number/masked-number';
import { STRINGS } from 'shared/constants';
import FlexBox from 'shared/components/flex-box';
import getLinkHandler from 'shared/functions/getLinkHandler';
import ErrorMessage from 'shared/components/error-message/error-message.component';
import { ERROR_TEXT } from 'shared/styles/colors';
import FccDisclaimer from 'shared/components/fcc-disclaimer/fcc-disclaimer.component';
import { LINKS } from 'shared/constants/strings';

declare global {
  interface Window {
    electronAPI: any;
  }
}

interface Inputs {
  username: string;
  password: string;
}

const LoginForm: FC = () => {
  const { login, loginError, loading, userInterfaceModeError } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setPhoneNumber(target.value);

  const handleOnBlur = () => setValue('username', phoneNumber);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(login)} id='login-form'>
        <FlexBox gap={24}>
          <FlexBox>
            <InputField
              id='phone-number-input'
              label='Sorenson Phone Number'
              placeholder='Sorenson Phone Number'
              error={!!errors.username || loginError}
              InputLabelProps={{ shrink: true, variant: 'standard' }}
              InputProps={{
                inputComponent: MaskedNumber as any,
              }}
              helperText={errors?.username?.message}
              {...register('username', {
                required: 'Sorenson Phone Number is required!',
                onChange: handleOnChange,
                onBlur: handleOnBlur,
              })}
            />

            <InputField
              id='password-input'
              label='Sorenson Password'
              placeholder='Sorenson Password'
              error={!!errors.password || loginError}
              helperText={errors?.password?.message}
              type={showPassword ? 'text' : 'password'}
              InputLabelProps={{ shrink: true, variant: 'standard' }}
              sx={{ marginBottom: '24px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      edge='end'
                    >
                      {showPassword ? (
                        <Visibility sx={{ color: 'text.disabled' }} />
                      ) : (
                        <VisibilityOff sx={{ color: 'text.disabled' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('password', {
                required: 'Sorenson Password is required!',
              })}
            />
            {loginError && userInterfaceModeError === '' && (
              <Typography sx={{ color: 'error.main', fontSize: '14px' }}>
                {STRINGS.ERROR_STRINGS.SIGN_IN_FAILURE}
              </Typography>
            )}
          </FlexBox>

          <LoadingButton type='submit' loading={loading} id='sign-in-button'>
            Sign In
          </LoadingButton>
          {userInterfaceModeError !== '' && (
            <ErrorMessage>
              <span
                style={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: ERROR_TEXT,
                }}
              >
                {userInterfaceModeError}
                <a
                  style={{
                    color: ERROR_TEXT,
                    position: 'relative',
                    zIndex: 999,
                  }}
                  href='mailto:SICustomerSupport@sorenson.com'
                >
                  {LINKS.SIGN_UP_SORENSON_EXPRESS}
                </a>
                ?
              </span>
            </ErrorMessage>
          )}
          <Link
            href={process.env.REACT_APP_FORGOT_PASSWORD_URL || ''}
            onClick={getLinkHandler}
            underline='none'
            id='forgot-password-button'
            sx={{
              color: 'text.primary',
              fontSize: '14px',
              textAlign: 'center',
              marginBottom: '16px ',
              position: 'relative',
              zIndex: 999,
            }}
          >
            Forgot Password?
          </Link>
        </FlexBox>
        <FccDisclaimer />
      </form>
    </Box>
  );
};

export default LoginForm;
