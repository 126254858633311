import React, { FC } from 'react';
import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import ThankYouBG from 'shared/assets/images/thank-you-bg2.png';
import { useNavigate } from 'react-router-dom';

import LoadingButton from 'shared/components/loading-button/loading-button.component';
import Page from 'shared/components/page/page.component';

const ThankYouPage: FC = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <Page bgImage={ThankYouBG} title='Thank You'>
      <Container
        sx={{
          [theme.breakpoints.down('md')]: {
            margin: '0px 0px',
          },
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginBottom: '150px',
          marginLeft: '150px',
        }}
      >
        <Box
          id='thank-you-page'
          sx={{
            [theme.breakpoints.down('md')]: {
              marginTop: '40px',
            },
            maxWidth: '350px',
          }}
        >
          <Typography variant='h2' fontSize={64} fontWeight='bold'>
            Thank you!
          </Typography>
          <Typography variant='subtitle1' fontSize={24}>
            An interpreter has been requested for your meeting.
          </Typography>
          <Divider sx={{ margin: '56px 0' }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <ArrowForwardIcon
              width={14}
              fontSize={'medium'}
              sx={{ marginRight: '12px' }}
            />
            <Typography variant='caption' sx={{ marginBottom: '16px' }}>
              Did the interpreter unexpectedly leave the meeting?
            </Typography>
          </Box>
          <LoadingButton
            id='request-interpreter-again-button'
            onClick={navigateHome}
            sx={{ marginTop: '24px', maxWidth: '330px' }}
            loading={false}
          >
            Request an interpreter again
          </LoadingButton>
        </Box>
      </Container>
    </Page>
  );
};

export default ThankYouPage;
