import { Typography, Link as LinkMui } from '@mui/material';
import React, { FC } from 'react';
import getLinkHandler from 'shared/functions/getLinkHandler';
import { BLACK_TEXT } from 'shared/styles/colors';

const FccDisclaimer: FC = () => {
  return (
    <>
      <hr
        style={{
          border: 'none',
          margin: '0px 0px 16px 0px',
          width: '100%',
          height: '1px',
          background: 'var(--stroke-separator, rgba(4, 8, 9, 0.05))',
        }}
      />
      <Typography
        sx={{
          fontSize: '12px',
          opacity: '75%',
          color: BLACK_TEXT,
        }}
      >
        (If you don’t have a Sorenson VRS account, you can apply now at{' '}
        <LinkMui
          id='apply-now-link'
          href='https://sorenson.com/solutions/video-relay-services'
          color={BLACK_TEXT}
          style={{ position: 'relative', zIndex: 999 }}
          onClick={getLinkHandler}
        >
          https://sorenson.com/solutions/video-relay-services.
        </LinkMui>
        )
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          margin: '16px 0px',
          color: 'text.primary',
        }}
      >
        This application for connecting to video conferencing services is in
        development and its features and functions may change as development
        continues. At this time, this application allows connections with only
        Zoom and not to all video conferencing services.
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          marginBottom: '16px',
          color: 'text.primary',
          '& a': {
            color: 'text.primary',
            textDecorationColor: 'text.primary',
            textDecoration: 'underline',
          },
        }}
      >
        Consumers should contact a Sorenson representative at (801) 386-8500 or{' '}
        <LinkMui
          id='support-sorenson-link'
          style={{ color: 'text.primary', position: 'relative', zIndex: 999 }}
          href='mailto:support@sorenson.com'
          onClick={getLinkHandler}
        >
          support@sorenson.com
        </LinkMui>{' '}
        if they cannot connect to a Zoom video conference with the application
        or if the application fails during a Zoom video conference after an
        initial connection.
      </Typography>
    </>
  );
};
export default FccDisclaimer;
