import * as React from 'react';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

interface Props {
  currentValue: string;
  values: Array<{ name: string; value: string }>;
  handleOnChange: (_: React.MouseEvent, value: any) => void;
}

const CustomToggleButton = styled(ToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    '&': {
      textTransform: 'capitalize',
      border: 'none',
      width: '160px',
      height: '42px',
      fontSize: '16px',
    },
    '&.MuiToggleButton-root': {
      color: theme.palette.text.secondary,
    },
    '&.Mui-selected': {
      background: theme.palette.primary.main,
    },
    '&.Mui-selected:hover': {
      background: theme.palette.primary.dark,
    },
  })
);

function ToggleButtons({ currentValue, values, handleOnChange }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box>
        <ToggleButtonGroup
          value={currentValue}
          exclusive
          onChange={handleOnChange}
          sx={{
            border: '1px solid #c7c9cc',
            padding: '3px',
            '& .MuiToggleButtonGroup-grouped': {
              '&:not(:first-of-type)': {
                borderRadius: '4px',
              },
              '&:first-of-type': {
                borderRadius: '4px',
              },
            },
          }}
        >
          {values.map(({ name, value }) => (
            <CustomToggleButton
              id={`${value}-tab-button`}
              key={name}
              value={value}
            >
              {name}
            </CustomToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
}

export default ToggleButtons;
