import React, { useEffect } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ThemeModeProvider } from 'shared/contexts/theme-mode.context';
import { AlertProvider } from 'shared/contexts/alert.context';
import Routes from 'router.component';
import { AuthContextProvider } from 'shared/contexts/auth-context';
import withRouter from 'shared/routes/withRouter';
import { Auth0Provider } from '@auth0/auth0-react';

declare let pendo: any;

function App() {
  useEffect(() => {
    if (!pendo) return;
    pendo.initialize({
      visitor: {
        id: 'VISITOR-UNIQUE-ID',
      },
    });
  }, []);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_DOMAIN || ''}
      clientId={process.env.REACT_APP_CLIENT_ID || ''}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUDIENCE}
    >
      <AuthContextProvider>
        <ThemeModeProvider>
          <AlertProvider>
            <Routes />
          </AlertProvider>
        </ThemeModeProvider>
      </AuthContextProvider>
    </Auth0Provider>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ENV_ID || '',
})(withRouter(App));
