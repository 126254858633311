import * as React from 'react';
import Box from '@mui/material/Box';
import BoxContent from './modal-box-content';
import Popover from '@mui/material/Popover';
import { useMediaQuery } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import { BODY_COLOR } from 'shared/styles/colors';

interface Props {
  instructions: Array<string>;
  cssId: string;
}

function TooltipInstructions({ instructions, cssId }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const style = {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '0',
      height: '0',
      marginLeft: `${matches ? '0px' : '-6px'}`,
      bottom: `${matches ? '45%' : '50%'}`,
      left: `${matches ? '100%' : '2.5%'}`,
      boxSizing: 'border-box',
      border: '1em solid black',
      borderColor: `${
        matches
          ? '#fff #fff transparent transparent'
          : 'transparent transparent #fff #fff'
      }`,
      transformOrigin: '0 0',
      transform: 'rotate(45deg)',
      boxShadow: `${
        matches
          ? '3px -3px 4px 0 rgba(0, 0, 0, 0.16)'
          : '-4px 4px 6px -2px rgba(0, 0, 0, 0.16)'
      }`,
    },
  };

  const handleClick = (event: any) => {
    return anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'copyLinkPopover' : undefined;

  return (
    <IconButton
      id={cssId}
      onClick={handleClick}
      sx={{
        padding: '0',
        color: BODY_COLOR,
      }}
    >
      <InfoOutlinedIcon fontSize={'small'} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClick}
        anchorOrigin={{
          vertical: `${matches ? 'center' : 'bottom'}`,
          horizontal: `${matches ? 'left' : 'right'}`,
        }}
        transformOrigin={{
          vertical: `${matches ? 'center' : 'center'}`,
          horizontal: `${matches ? 'right' : 'left'}`,
        }}
        sx={{
          '& .MuiPopover-paper': {
            margin: `${matches ? '0px 0px 0px -28px' : '0px 0 0 30px'}`,
            overflow: 'visible',
            bgcolor: 'background.default',
            borderRadius: '15px',
            width: '282px',
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          },
        }}
      >
        <Box sx={style}>
          <BoxContent instructions={instructions} />
        </Box>
      </Popover>
    </IconButton>
  );
}

export default TooltipInstructions;
